<template>
  <v-dialog
    v-model="showModal"
    :persistent="persistent"
    max-width="510"
  >
    <v-card class="pa-8">
      <div
        v-if="icon !== null"
        class="d-flex justify-center"
      >
        <v-icon
          :color="iconColor || 'blue-grey lighten-2'"
          size="86px"
          light
        >
          {{ icon }}
        </v-icon>
      </div>
      <v-card-title class="d-flex justify-center pb-0 pt-8 subtitle-2 text-center">
        {{ titleModal }}
      </v-card-title>
      <v-card-text class="text-center text--disabled">
        {{ messageModal }}
      </v-card-text>
      <v-card-actions class="justify-center justify-space-around mt-4">
        <v-btn
          color="primary secondary--text px-6"
          large
          @click="agree"
        >
          {{ agreeTextBottom || $t('btn.default_agree') }}
        </v-btn>
        <v-btn
          v-if="showDisagreeButton"
          color="primary secondary--text px-6"
          large
          @click="disagree"
        >
          {{ disagreeTextBottom || $t('btn.default_desagree') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmationModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      description: 'Controla cuando muestra la modal'
    },
    icon: {
      type: String,
      default: null,
      description: 'Mesanje de la modal'
    },
    iconColor: {
      type: String,
      default: null,
      description: 'Mesanje de la modal'
    },
    titleModal: {
      type: String,
      required: true,
      description: 'Mesanje de la modal'
    },
    messageModal: {
      type: String,
      default: null,
      description: 'Mesanje de la modal'
    },
    agreeTextBottom: {
      type: String,
      default: null,
      description: 'Mensaje botón para aceptar'
    },
    showDisagreeButton: {
      type: Boolean,
      default: true,
      decription: 'Muestra y oculta el boton para cancelar modal'
    },
    disagreeTextBottom: {
      type: String,
      default: null,
      description: 'Mensaje botón para cancelar'
    },
    persistent: {
      type: Boolean,
      default: true,
      description: 'La modal no se cierra al presionar fuera de ella'
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    agree () {
      this.$emit('resolve', true)
    },
    disagree () {
      this.$emit('resolve', false)
    }
  }
}
</script>

<i18n>
{
  "es": {
    "btn": {
      "default_agree": "Aceptar",
      "default_desagree": "Cancelar"
    }
  }
}
</i18n>
