'use strict'

import moment from 'moment-timezone'

const formatDateMixin = {
  methods: {
    /**
     * Method to change the date format
     *
     * @author Andres Correa
     * @since  28/05/2020 04:38 PM
     * @version 1.0.0
     */
    $_formatDateMixin_formatDate: function (value) {
      if (value == null) return ''
      return moment.tz(value * 1000, process.env.VUE_APP_TIME_ZONE).format('DD/MM/YYYY')
    },
    /**
     * Method to change the time format
     *
     * @author Juan P. Bolivar
     * @since  28/05/2020
     * @version 1.0.0
     */
    $_formatDateMixin_formatTime: function (value) {
      if (value == null) return ''
      return moment.tz(value * 1000, process.env.VUE_APP_TIME_ZONE).format('HH:mm:ss')
    }
  }
}

export default formatDateMixin
