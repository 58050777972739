<template>
  <div v-if="tenderInfo !== null">
    <v-container
      class="col col-10"
      fill-height
      fluid
    >
      <v-col>
        <v-row>
          <h1 class="headline font-weight-bold text-uppercase my-auto">
            {{ tenderInfo.tenderName }}
          </h1>
        </v-row>
        <v-divider class="my-3 primary" />
        <v-row>
          <v-col
            cols="12"
          >
            <v-container class="py-0">
              <v-row class="mb-3">
                <span class="col col-12 pa-0 grey--text">
                  {{ $t('contractor') }}:
                </span>
                <span
                  class="col col-12 pa-0"
                >
                  {{ tenderInfo.tenderOwner }}
                </span>
              </v-row>
              <v-row>
                <span class="col col-12 pa-0 grey--text">
                  {{ $t('object_to_contract') }}:
                </span>
                <span class="col col-12 pa-0">
                  {{ tenderInfo.tenderDescription }}
                </span>
              </v-row>
            </v-container>
          </v-col>
          <v-col class="col-12 ">
            <v-container class="py-0 d-flex">
              <v-row class="mb-lg-0">
                <span class="col col-12 pa-0 grey--text">
                  {{ $t('close_date') }}:
                </span>
                <span class="col col-12 pa-0 my-auto">
                  {{ $_formatDateMixin_formatDate(tenderInfo.tenderDueDate) }}
                </span>
              </v-row>
              <v-row>
                <span class="col col-12 pa-0 grey--text">
                  {{ $t('close_time') }}:
                </span>
                <span class="col col-12 pa-0 my-auto">
                  {{ $_formatDateMixin_formatTime(tenderInfo.tenderDueDate) }}
                </span>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-2 fill-height text-center">
        <v-divider :vertical="true" />
      </v-col>
      <v-col class="col-4">
        <v-row
          align="center"
          justify="center"
        >
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
          >
            <v-form
              name="formulario-descifrar-archivo"
              @submit.prevent="handleSubmit(() => { showConfirmationModal = true })"
            >
              <v-row align="center">
                <ValidationProvider
                  ref="cerPassword"
                  v-slot="{ errors }"
                  :name="$t('form.cerPassword')"
                  rules="required|max:60"
                >
                  <div>
                    <v-text-field
                      id="cerPassword"
                      v-model="model.cerPassword"
                      :label="$t('form.cerPassword')"
                      :error-messages="errors"
                      name="cerPassword"
                      class="mx-auto"
                      type="password"
                      outlined
                      rounded
                    />
                  </div>
                </ValidationProvider>
              </v-row>
              <v-row>
                <v-btn
                  class="mx-auto primary secondary--text"
                  type="submit"
                >
                  {{ $t('btn.decryptFile') }}
                </v-btn>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-row>
      </v-col>
    </v-container>
    <confirmation-modal
      :show-modal="showConfirmationModal"
      :title-modal="$t('modals.confirmation.title')"
      :message-modal="$t('modals.confirmation.message')"
      :agree-text-bottom="$t('modals.confirmation.btn_agree')"
      :disagree-text-bottom="$t('modals.confirmation.btn_disagree')"
      @resolve="resolveConfirmationModal"
    />
    <confirmation-modal
      :show-modal="showFinishedProcessModal"
      :title-modal="$t('modals.finished_process.title')"
      :message-modal="$t('modals.finished_process.message')"
      :agree-text-bottom="$t('modals.finished_process.btn_agree')"
      :show-disagree-button="false"
      @resolve="resolveFinishedProcessModal"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store/index'
import FormatDateMixin from '@/mixins/formatDateMixin'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import { NOT_FOUND, BAD_REQUEST, CONFLICT } from '@/shared/constants/httpStatus'
import ConfirmationModal from '@/components/uiComponents/modals/confirmationModal/ConfirmationModal'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST, SHOW_ALERT, HIDE_ALERT } from '@/store/mutations-types'
import { URL_DECRYPT_FILE, URL_VALIDATE_HASH, ROUTE_HOME } from './data/decryptFileData'

/**
 *
 * @author Juan P. Bolivar
 * @since 18/05/2020
 * @version 1.0.0
 */
export default {
  name: 'DecryptFile',
  components: {
    ConfirmationModal
  },
  mixins: [
    handleErrorMixin,
    FormatDateMixin
  ],
  data () {
    return {
      tenderInfo: null,
      model: {
        cerPassword: null,
        hash: this.$route.params.hash
      },
      showConfirmationModal: false,
      showFinishedProcessModal: false
    }
  },
  /**
   *
   * @author Juan P. Bolivar
   * @version 1.0.0
   */
  beforeRouteEnter (to, from, next) {
    if (to.params.hash) {
      store.commit(ADD_COUNTER_REQUEST)
      Vue.axios.get(URL_VALIDATE_HASH, { params: { hash: to.params.hash } }).then(response => {
        store.commit(SUBTRACT_COUNTER_REQUEST)
        next(vm => { vm.tenderInfo = response.data })
      }).catch(() => {
        store.commit(SUBTRACT_COUNTER_REQUEST)
        next(ROUTE_HOME)
      })
    } else {
      next(ROUTE_HOME)
    }
  },
  methods: {
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    decryptFile () {
      this.$store.commit(HIDE_ALERT)
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.model.stageId = this.tenderInfo.stageId
      this.model.documentId = this.tenderInfo.documentId
      this.axios.post(URL_DECRYPT_FILE, this.model).then((response) => {
        this.showFinishedProcessModal = true
      }).catch((error) => {
        if (error.response.status === NOT_FOUND || error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: error.response.data.errorMessage,
            show: true
          })
          return
        } else if (error.response.status === CONFLICT) {
          this.$store.commit(SHOW_ALERT, {
            type: 'error',
            text: error.response.data.errorMessage,
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    resolveConfirmationModal (resolve) {
      if (resolve) {
        this.decryptFile()
      }
      this.showConfirmationModal = false
    },
    /**
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     */
    resolveFinishedProcessModal () {
      this.showFinishedProcessModal = false
      this.$router.push(ROUTE_HOME)
    }
  }
}
</script>

<i18n>
{
  "es": {
    "contractor": "CONTRATANTE",
    "object_to_contract": "OBJETO A CONTRATAR",
    "close_date": "FECHA DE CIERRE",
    "close_time": "HORA DE CIERRE",
    "form": {
      "cerPassword": "Clave de descifrado"
    },
    "btn": {
      "decryptFile": "Descifrar archivo"
    },
    "modals": {
      "confirmation": {
        "title": "COMFIRMACION DE DESCIFRADO",
        "message": "¿Está seguro desea descifrar el archivo?",
        "btn_agree": "Si",
        "btn_disagree": "No"
      },
      "finished_process": {
        "title": "ARCHIVO DESCIFRADO",
        "message": "El archivo se ha descifrado con exito.",
        "btn_agree": "Continuar"
      }
    }
  }
}
</i18n>
